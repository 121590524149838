<template>
    <div>
        <a-card>
            <template slot="title">
                <strong class="">Februari 2025</strong>
            </template>
            <div class="row">
                <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                    <label class="statistics-title">PO</label>
                    <br>
                    <label class="statistics-value">
                        35</label> <a-icon type="reconciliation" class="ml-1 statistics-icons"/>
                </div>
                <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                    <label class="statistics-title">Products</label>
                    <br>
                    <label
                        class="statistics-value">
                        20.000</label><a-icon type="appstore" class="ml-1 statistics-icons"/>
                </div>
                <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                    <label class="statistics-title">Amounts</label>
                    <br>
                    <label
                        class="statistics-value">
                        Rp. 100.000.000</label>
                </div>
                <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                    <label class="statistics-title">Reject</label>
                    <br>
                    <label
                        class="statistics-value">
                        4.000 (20%)</label><a-icon type="appstore" class="ml-1 statistics-icons"/>
                </div>
                <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                    <label class="statistics-title">Stok Sisa Titipan</label>
                    <br>
                    <label
                        class="statistics-value">
                        2.000 (10%)</label><a-icon type="appstore" class="ml-1 statistics-icons"/>
                </div>
                <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                    <label class="statistics-title">Invoice Terbayar</label>
                    <br>
                    <label
                        class="statistics-value">
                        80% (40/50)</label><a-icon type="reconciliation" class="ml-1 statistics-icons"/>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">

                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <label style="color: rgba(0, 0, 0, 0.85); font-size: 18px;">Latest Order</label><a-icon type="container" class="ml-1" style="font-size: 18px;"/>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2"><label
                                style="color: rgba(0, 0, 0, 0.55); font-size: 14px;">PO. No.</label></div>
                        <div class="col-xs-10 col-sm-10 col-md-10 col-lg-10">
                            <label style="color: rgba(0, 0, 0, 0.7); font-size: 14px;">: INVC 035/II/2025</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2"><label
                                style="color: rgba(0, 0, 0, 0.55); font-size: 14px;">Amounts</label></div>
                        <div class="col-xs-10 col-sm-10 col-md-10 col-lg-10">
                            <label style="color: rgba(0, 0, 0, 0.7); font-size: 14px;">: 10.000.000</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2"><label
                                style="color: rgba(0, 0, 0, 0.55); font-size: 14px;">Product QTY</label></div>
                        <div class="col-xs-10 col-sm-10 col-md-10 col-lg-10">
                            <label style="color: rgba(0, 0, 0, 0.7); font-size: 14px;">: 5.000</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2"><label
                                style="color: rgba(0, 0, 0, 0.55); font-size: 14px;">In Production</label></div>
                        <div class="col-xs-10 col-sm-10 col-md-10 col-lg-10">
                            <label style="color: rgba(0, 0, 0, 0.7); font-size: 14px;">: 3.000 (60%)</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2"><label
                                style="color: rgba(0, 0, 0, 0.55); font-size: 14px;">Delivered</label></div>
                        <div class="col-xs-10 col-sm-10 col-md-10 col-lg-10">
                            <label style="color: rgba(0, 0, 0, 0.7); font-size: 14px;">: 2.000 (40%)</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2"><label
                                style="color: rgba(0, 0, 0, 0.55); font-size: 14px;">Paid Invoice</label></div>
                        <div class="col-xs-10 col-sm-10 col-md-10 col-lg-10">
                            <label style="color: rgba(0, 0, 0, 0.7); font-size: 14px;">: 2.000.000 (20%)</label>
                        </div>
                    </div>
                </div>
            </div>
        </a-card>
        <!-- <a-card>
            <template slot="title">
                <strong class="">Title</strong>
            </template>
            <div class="row">
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <label class="statistics-title">Total Order Masuk</label>
                            <br>
                            <label
                                class="statistics-value">
                                500</label>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <label class="statistics-title">Total Produksi Selesai</label>
                            <br>
                            <label
                                class="statistics-value">
                                450</label>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <label class="statistics-title">Total Pengiriman Dikirim</label>
                            <br>
                            <label
                                class="statistics-value">
                                400</label>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <label class="statistics-title">Invoice Terbayar</label>
                            <br>
                            <label
                                class="statistics-value">
                                80% (400/500)</label>
                        </div>
                    </div>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <label style="color: rgba(0, 0, 0, 0.85); font-size: 18px;">Latest Order</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2"><label
                                style="color: rgba(0, 0, 0, 0.55); font-size: 14px;">PO. No.</label></div>
                        <div class="col-xs-10 col-sm-10 col-md-10 col-lg-10">
                            <label style="color: rgba(0, 0, 0, 0.7); font-size: 14px;">: INVC 035/II/2025</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2"><label
                                style="color: rgba(0, 0, 0, 0.55); font-size: 14px;">Amounts</label></div>
                        <div class="col-xs-10 col-sm-10 col-md-10 col-lg-10">
                            <label style="color: rgba(0, 0, 0, 0.7); font-size: 14px;">: 10.000.000</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2"><label
                                style="color: rgba(0, 0, 0, 0.55); font-size: 14px;">Product QTY</label></div>
                        <div class="col-xs-10 col-sm-10 col-md-10 col-lg-10">
                            <label style="color: rgba(0, 0, 0, 0.7); font-size: 14px;">: 5000</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2"><label
                                style="color: rgba(0, 0, 0, 0.55); font-size: 14px;">In Production</label></div>
                        <div class="col-xs-10 col-sm-10 col-md-10 col-lg-10">
                            <label style="color: rgba(0, 0, 0, 0.7); font-size: 14px;">: 3000 (60%)</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2"><label
                                style="color: rgba(0, 0, 0, 0.55); font-size: 14px;">Delivered</label></div>
                        <div class="col-xs-10 col-sm-10 col-md-10 col-lg-10">
                            <label style="color: rgba(0, 0, 0, 0.7); font-size: 14px;">: 2000 (40%)</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2"><label
                                style="color: rgba(0, 0, 0, 0.55); font-size: 14px;">Paid Invoice</label></div>
                        <div class="col-xs-10 col-sm-10 col-md-10 col-lg-10">
                            <label style="color: rgba(0, 0, 0, 0.7); font-size: 14px;">: 2.000.000 (20%)</label>
                        </div>
                    </div>
                </div>
            </div>
        </a-card> -->
        <!-- <modal name="carinasabahmodal" :maskClosable="false" :draggable="true" :footer="null"></modal> -->
    </div>
</template>

<script>
import * as lou from '@/services/data/lou'
import debounce from 'lodash/debounce'
import moment from 'moment'
// import Cleave from 'vue-cleave-component'
import { mapState } from 'vuex'
import { Modal } from 'ant-design-vue'

export default {
  computed: {
    ...mapState(['settings', 'user']),
  },
  components: {
    // cleave: Cleave,
  },
  created() {
    this.getMaster()
  },
  watch: {
    // Alternate component "Cleave" tidak dapat menggunakan @input ataupun @change
    mutasi(newValue, oldValue) {
      // console.log('newValue', newValue)
      // console.log('oldValue', oldValue)
      this.mutasiChange()
    },
  },
  data() {
    this.lastFetchId = 0
    this.fetchUser = debounce(this.fetchUser, 800)
    return {
      selectedRekening: [],
      selectedName: [],
      tanggalTransaksi: moment(),
      norekening: '',
      namaprekening: '',
      alamatprekening: '',
      kodetransaksi: '',
      keterangan: '',
      saldoprekening: null,
      mutasi: null,
      saldoakhir: null,
      searchText: '',
      searchInput: null,
      searchedColumn: '',
      fetching: false,
      dataRekening: [],
      carinasabahmodal: {
        visible: false,
        column: [],
        datatable: [],
      },
      columns: [
        {
          title: 'Tanggal Transaksi',
          key: 'tgl',
          scopedSlots: { customRender: 'tgl' },
        },
        {
          title: 'Nomor Transaksi',
          dataIndex: 'faktur',
          scopedSlots: { customRender: 'nomortransaksi' },
        },
        {
          title: 'Keterangan',
          dataIndex: 'keterangan',
          scopedSlots: { customRender: 'keterangan' },
        },
        {
          title: 'D/K',
          dataIndex: 'dk',
          width: 70,
          scopedSlots: { customRender: 'dk' },
        },
        {
          title: 'Jumlah',
          dataIndex: 'jumlah',
          scopedSlots: { customRender: 'jumlah' },
        },
        // {
        //   title: 'Akses',
        //   dataIndex: 'privileges',
        //   scopedSlots: { customRender: 'privileges' },
        // },
      ],
      data: [],
      masterKodeTransaksi: [],
      componentKey: 0,
    }
  },
  methods: {
    changeDateInput(dateMoment, dateString) { },
    changeKodeTransaksi() {
      var targetIndex = this.masterKodeTransaksi.findIndex(x => x.kode === this.kodetransaksi)
      var dataTarget = this.dataRekening[this.selectedRekening.key]
      this.keterangan = this.masterKodeTransaksi[targetIndex].keterangan + ' a.n ' + dataTarget.nama
    },
    mutasiChange() {
      if (this.mutasi !== null && this.kodetransaksi !== '') {
        var targetKode = this.masterKodeTransaksi.findIndex(x => x.kode === this.kodetransaksi)
        var total = 0
        // console.log('this.mutasi', this.mutasi)
        if (this.masterKodeTransaksi[targetKode].kd === 'K') {
          total = parseInt(this.saldoprekening) - parseInt(this.mutasi)
          // console.log('this.mutasi', this.mutasi)
        } else {
          total = parseInt(this.saldoprekening) + parseInt(this.mutasi)
          // console.log('this.mutasi', this.mutasi)
        }
        this.saldoakhir = isNaN(total) ? 0 : lou.curency(total)
        // console.log('this.mutasi', this.mutasi)
        // console.log('total', total)
      }
    },
    async getMaster() {
      var res = await lou.customUrlGet('mutasitabungan/kodetransaksi')
      if (res) {
        this.masterKodeTransaksi = res.data
      }
      var resp = await lou.customUrlGet('mutasitabungan')
      if (resp) {
        this.data = resp.data
      }
    },
    async getAllData() {
      var res = await lou.customUrlGet('mutasitabungan/cari_rekening/' + this.norekening)
      console.log('res', res)
    },
    async saveMutasi() {
      var targetKode = this.masterKodeTransaksi.findIndex(x => x.kode === this.kodetransaksi)
      var fd = {
        posting: null,
        faktur: null,
        tgl: this.tanggalTransaksi.format('YYYY-MM-DD'),
        kodetransaksi: this.masterKodeTransaksi[targetKode].kode,
        rekeningjurnal: 'string',
        dk: this.masterKodeTransaksi[targetKode].dk,
        rekening: this.norekening,
        jumlah: 0,
        debet: 0,
        kredit: 0,
        username: this.user.name,
        status: null,
        keterangan: this.keterangan,
        statusprinter: null,
        jenisslip: null,
        noslip: null,
        useracc: null,
      }
      var ntotal = this.saldoakhir.replaceAll(',', '')
      fd.jumlah = parseInt(ntotal)
      if (this.masterKodeTransaksi[targetKode].dk === 'K') {
        fd.kredit = parseInt(this.mutasi)
      } else {
        fd.debet = parseInt(this.mutasi)
      }
      // console.log('fd', fd)
      var res = await lou.customUrlPost('mutasitabungan', fd, true)
      if (res) {
        Modal.confirm({
          title: 'Cetak Validasi?',
          content: 'Cetak validasi tabungan?',
          okText: 'Yes',
          okType: 'primary',
          cancelText: 'No',
          onOk: () => {
            this.generateValidasi()
            this.nextStep()
          },
          onCancel: () => {
            this.nextStep()
            // console.log('Cancel')
          },
        })
      }
    },
    nextStep() {
      Modal.confirm({
        title: 'Cetak Buku?',
        content: 'Cetak di buku tabungan?',
        okText: 'Yes',
        okType: 'primary',
        cancelText: 'No',
        onOk: () => {
          this.generateBuku()
        },
        onCancel: () => {
          // console.log('Cancel')
        },
      })
    },
    generateValidasi() { },
    generateBuku() { },
    async fetchUser(value) {
      // console.log('fetching user', value)
      if (value !== '') {
        this.lastFetchId += 1
        const fetchId = this.lastFetchId
        this.dataRekening = []
        this.fetching = true
        var res = false
        if (!this.hasNumber(value)) {
          var nvalue = value.replace(/[^\w\s]/gi, '')
          res = await lou.customUrlGet('mutasitabungan/cari?Nama=' + nvalue)
        } else {
          var nvalue1 = value.replace(/[0-9]/g, '')
          res = await lou.customUrlGet('mutasitabungan/cari_rekening/' + nvalue1)
        }
        if (res) {
          if (fetchId !== this.lastFetchId) {
            // for fetch callback order
            return false
          }
          this.dataRekening = res.data
          this.fetching = false
        } else {
          this.fetching = null
        }
      }
    },
    handleChange(value, from) {
      // console.log('value', value)
      if (value.key !== null && value.key !== undefined) {
        var dataTarget = this.dataRekening[value.key]
        var data = {
          selectedName: value,
          selectedRekening: value,
          norekening: dataTarget.rekening,
          namaprekening: dataTarget.nama,
          alamatprekening: dataTarget.alamat,
          saldoprekening: dataTarget.saldo,
          // dataRekening: [],
          fetching: false,
        }
        Object.assign(this, data)
      }
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },
    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },
    hasNumber(myString) {
      return /\d/.test(myString)
    },
    formatTanggal(v) {
      var ntgl = v.tgl.substring(0, 10)
      console.log('ntgl', ntgl)
      return moment(ntgl, 'YYYY-MM-DD').format('DD-MM-YYYY')
    },
    formatCurrency(v) {
      return lou.curency(v)
    },
  },
}
</script>

<style>
.statistics-title {
  color: rgba(0, 0, 0, 0.45);
}
.statistics-value {
  color: rgba(0, 0, 0, 0.85); font-size: 24px;font-family: -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol
}
.statistics-icons {
  font-size: 20px;color: rgba(0, 0, 0, 0.85);
}
</style>
